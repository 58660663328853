<template>
  <el-dialog title="财报下载" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <table border="1">
      <thead>
        <tr>
          <th>标题</th>
          <th>文件</th>
          <th>上传时间</th>
          <th>操作</th>

        </tr>
      </thead>
      <tbody v-if="getLists.length">
        <tr v-for="(item, index) in getLists" :key="index">
          <td>{{ item.title }}</td>
          <td class="Line" @click="toLookPDF(item.files)">
            {{ JSON.parse(item.files)[0].FilesName }}
          </td>
          <td>{{ item.createTime ? item.createTime.substr(0, 10) : "" }}</td>
          <td>
            <el-button type="success" @click="toLookPDF(item.files)">查看</el-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4">暂无数据</td>
        </tr>
      </tbody>
    </table>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
    <lookPDF v-if="isLook" @closeDialog3="closeDialog3" ref="isLook"></lookPDF>
  </el-dialog>
</template>

<script>
  import lookPDF from './LookPdf.vue'
  import { selectExport, download } from "@/api/ChargeUp";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        dialogVisible: true,
        getLists: [], //这是查看列表
        isLook: false, //预览图片
      };
    },
    components: {
      lookPDF
    },
    mounted() {
      //查询会计人员接口
      this.getList();
    },
    computed: {
      ...mapGetters(["identityObj"]),
    },
    methods: {
      //关闭预览
      closeDialog3(isLook) {
        this.isLook = isLook;
      },
      //预览pdf
      toLookPDF(lookPDF) {
        this.isLook = true,
          this.$nextTick(() => {
            this.$refs.isLook.getPDF(lookPDF);
          });
      },
      // 返回
      back() {
        this.$emit("closeDialog2", false);
      },
      handleClose() {
        this.$emit("closeDialog2", false);
      },
      // submit() {
      //   this.dialogVisible = false;
      // },
      //用户财报查询
      async getList() {
        console.log(this.identityObj);
        let params = {
          userId: this.identityObj.userId,
          identityId: this.identityObj.identityId,
        };
        let res = await selectExport(params);
        this.getLists = res.data;
      },
      //财报下载
      async Download(item) {
        // let res = await download();
        // if (res) {
        //   let blob = res;
        //   const fileName = item.title + "-" + JSON.parse(item.files)[0].FilesName;
        //   let downloadElement = document.createElement("a");
        //   let binaryData = [];
        //   binaryData.push(blob);
        //   let href = window.URL.createObjectURL(
        //     // |.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z
        //     new Blob(binaryData, {
        //       type: "application/xlsx",
        //     })
        //   ); //创建下载的链接
        //   downloadElement.href = href;
        //   downloadElement.download = decodeURIComponent(fileName); //解码
        //   document.body.appendChild(downloadElement);
        //   downloadElement.click();
        //   document.body.removeChild(downloadElement);
        //   window.URL.revokeObjectURL(href);
        // }
        // let itemList = JSON.parse(item.files)[0];
        // let dom = document.createElement("a");
        // dom.style.display = "none";
        // dom.href = "/api/admin/file/get?ossFilePath=" + itemList.imgUrl;
        // const fileName = "xxx用户财务报表";
        // dom.setAttribute("download", fileName);
        // document.body.appendChild(dom);
        // dom.click();
        let itemList = JSON.parse(item.files)[0];
        // console.log(itemList, "查看");
        let res = await download({ ossFilePath: itemList.imgUrl });
        if (res) {
          let blob = res;
          const fileName = itemList.FilesName;
          let downloadElement = document.createElement("a");
          let binaryData = [];
          binaryData.push(blob);
          // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
          let href = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
            })
          ); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(fileName); //解码
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    tr {
      td {
        height: 50px;
        text-align: center;
      }

      .Line {
        text-decoration: underline;
        color: #1e1ec6;
      }
    }
  }
</style>
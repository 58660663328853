<template>
  <div class="pdf-container">
    <el-dialog title="财报展示" append-to-body :visible.sync="showPDF" width="1000px" :before-close="handleClose">
      <div class="content">
        <div class="content" v-for="page in pageAllNum" :key="page">
          <pdf :src="pdfSrc" :page="page"></pdf>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="Cancel">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import pdf from "vue-pdf";
  import { companyPusgPdf, } from '@/api/add'
  export default {
    data() {
      return {
        showPDF: true,
        pdfSrc: "", // pdf文件src
        pageAllNum: 1, // pdf文件总页数
        baseUrl: window.globalUrl.HOME_API + "admin/file/get?ossFilePath=",
      }
    },
    components: {
      pdf,
    },
    methods: {

      async getPDF(lookPDF) {
        console.log(JSON.parse(lookPDF), 'lookPDF')
        console.log(JSON.parse(lookPDF)[0].imgUrl, 'lookPDF.imgUrl')
        this.pdfSrc = this.baseUrl + JSON.parse(lookPDF)[0].imgUrl;
        console.log(this.pdfSrc, 'this.pdfSrc')

      },
      //点击关闭弹窗
      handleClose() {
        this.$emit("closeDialog3", false);
      },
      // 取消弹窗按钮
      Cancel() {
        this.$emit("closeDialog3", false);
      },
    },
  }

</script>
<style lang="less" scoped>
  .content {
    width: 90%;
    min-width: 860px;
    margin: 10px auto;
    padding: 10px;
    background: rgba(85, 85, 85, 0.1);
    box-sizing: border-box;
    margin-top: 0;
  }
</style>
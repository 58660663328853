<template>
  <el-dialog
    :title="IsIncome ? '收入凭证' : '支出凭证'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="36%"
    height="1000px"
    center
  >
    <div class="UpList">
      <div class="UploadCredentials">
        <div class="title">
          <span>凭证文件（必填）：</span>
        </div>
        <div class="innerText">
          <el-upload
            v-show="isFlag"
            class="upload-demo"
            :action="uploadUrl"
            name="files"
            :disabled="loading"
            :auto-upload="false"
            :on-change="handleChange"
            :file-list="fileList"
            :show-file-list="false"
          >
            <i class="el-icon-download"></i>
          </el-upload>

          <!-- <img
            v-if="ImgItem"
            :src="'/api/admin/file/get?ossFilePath=' + ImgItem.imgUrl"
            :alt="ImgItem.FilesName"
          /> -->

          <el-image
            v-if="!isFlag"
            :src="'/api/admin/file/get?ossFilePath=' + ImgItem[0].imgUrl"
            @click="AddFiles(item)"
          >
            <div slot="error" class="image-slot">
              <span>{{ ImgItem[0].FilesName }}</span>
            </div>
          </el-image>
        </div>
      </div>
      <div class="remark">
        <div class="title">
          <span>备注（选填）：</span>
        </div>
        <div class="innerText">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="remarkText"
          >
          </el-input>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="Cancel()">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submit()"> {{ loading ? "提交中" : "提交" }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
// 图片上传
import { uploadFile } from "@/api/fileApi";
import { UploadCredentials } from "@/api/ChargeUp";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: true, //弹窗开启状态
      loading: false, //上传文件的状态
      IsIncome: false, //当前页面状态：支出还是收入
      remarkText: "", //备注的内容
      fileList: [], //上传图片列表
      isFlag: true, // 上传按钮是否展示
      creator_type: "", //支出或者收入的类型
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      ImgItem: [
        {
          FilesName: "",
          imgUrl: "",
        },
      ], //上传图片的路径
    };
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  mounted() {},

  methods: {
    // 取消弹窗按钮
    Cancel() {
      this.$emit("closeDialog", false);
    },
    //点击关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    //上传凭证图片
    async handleChange(file) {
      this.loading = true;
      let param = new FormData();
      param.append("catalog", "proof");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      if(res.code==0){
        this.loading = false;
      }
      let imgUrl = res.data.url;
      let sourceFileName = res.data.sourceFileName;

      this.ImgItem = [
        {
          FilesName: sourceFileName,
          imgUrl: imgUrl,
        },
      ];

      this.isFlag = false;
    },

    // 提交
    async submit() {

      if (this.ImgItem[0].imgUrl) {
        let file = JSON.stringify(this.ImgItem);
        let params = {
          files: file,
          remark: this.remarkText,
          fileType: this.creator_type,
          creatorType: this.identityObj.identityTypeCode,
        };
        let res = await UploadCredentials(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.warning(res.msg);
        }

        this.$emit("closeDialog", false);
      } else {
        this.$message.info("请上传文件");
      }
      
      console.log(9999,this.fileList);
    },
    //支出
    disburse(creator_type) {
      this.IsIncome = false;
      this.creator_type = creator_type;
    },
    // 收入
    income(creator_type) {
      this.IsIncome = true;
      console.log("收入");
      this.creator_type = creator_type;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  height: 300px;
  .UpList {
    width: 100%;
    height: 300px;
    .UploadCredentials {
      display: flex;
      height: 70%;
      .title {
        width: 30%;
        height: 50px;
        font-size: 15px;
        font-weight: bold;
      }
      .innerText {
        width: 70%;
        .el-icon-download {
          font-size: 30px;
          font-weight: bold;
        }
        .el-image {
          img {
            width: 388px;
            height: 210px;
          }
        }

        /*  img {
          width: 100%;
          height: 100%;
        }*/
      }
    }
    .remark {
      padding: 10px;
      display: flex;
      height: 30%;
      .title {
        width: 30%;
        font-size: 15px;
        font-weight: bold;
      }
      .innerText {
        width: 70%;
      }
    }
  }
}
</style>
